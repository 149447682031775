<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>Preguntas</b></h4>
          </template>
          <div class="text-right px-4 mt-3"></div>
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_preguntas"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Título Preguntas</th>
                  <th>Tipo</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.descripcion }}</td>

                  <td>{{ item.tipoCompetencia.descripcion }}</td>

                  <td>
                    <b-button>
                      <i
                        class="fas fa-eye mx-auto"
                        @click="$router.push('Preguntas_lista/' + item.id)"
                      ></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      tabla_test: [],
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      row: {
        titulo: "",
        tipoCompetencia: {
          descripcion: "",
        },
        descripcion: "",
      },
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_preguntas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    showModal(item) {
      this.row = item;
      this.$bvModal.show("my-modal");
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Competencia/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/ListCompetencia/",
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
